import React from 'react'

import AddAllergyLayout from 'presentation/doctor/layouts/AddAllergy'
import { AddAllergy as IAddAllergy } from 'domain/usecases/allergy/add-allergy'

type Props = {
  addAllergy: IAddAllergy
}

export default function AddAllergy({ addAllergy }: Props) {
  return <AddAllergyLayout addAllergy={addAllergy} patient_id={1} />
}
