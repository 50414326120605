import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteLoadDoctor } from 'service/usecases/load-doctor/remote-load-doctor'
import UpdateDoctorPersonalInfo from 'presentation/doctor/pages/UpdatePersonalInfo'

export default function makeDoctorPersonalInfo() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const doctorRepository = new DoctorRepository(apiRepository)

  const loadDoctor = new RemoteLoadDoctor(doctorRepository)

  return <UpdateDoctorPersonalInfo loadDoctor={loadDoctor} />
}
