import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Secretary } from 'domain/entities/secretary-model'
import SecretaryDetailsLayout from 'presentation/doctor/layouts/SecretaryDetails'
import { UnlinkSecretary } from 'domain/usecases/secretary/unlink-secretary'
import { UpdateUser } from 'domain/usecases/user/update-user'

type Props = {
  unlinkSecretary: UnlinkSecretary
  updateSecretaryProfile: UpdateUser
}

export default function SecretaryDetails({
  unlinkSecretary,
  updateSecretaryProfile
}: Props) {
  const { state } = useLocation<{ secretary: Secretary }>()
  const history = useHistory()

  if (!state?.secretary) {
    history.goBack()
  }

  return (
    <SecretaryDetailsLayout
      updateSecretaryProfile={updateSecretaryProfile}
      secretary={state.secretary}
      unlinkSecretary={unlinkSecretary}
    />
  )
}
