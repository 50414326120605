import React from 'react'
import DoctorOnboardingLayout from 'presentation/doctor/layouts/DoctorOnboarding'
import { FinishAppOnboarding } from 'domain/usecases/user/finish-app-onboarding'

type DoctorOnboardingProps = {
  finishAppOnboarding: FinishAppOnboarding
}

export default function DoctorOnboarding({
  finishAppOnboarding
}: DoctorOnboardingProps) {
  return <DoctorOnboardingLayout finishAppOnboarding={finishAppOnboarding} />
}
