import styled from 'styled-components'

export const Wrapper = styled.form`
  & > p {
    margin-bottom: 40px;
  }
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Buttons = styled.div`
  width: 100%;
  text-align: center;
`

export const Content = styled.div`
  padding-bottom: 100px;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`
