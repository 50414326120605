import React, { useState, useEffect } from 'react'

import SecretariesLayout from 'presentation/doctor/layouts/Secretaries'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { Secretary } from 'domain/entities/secretary-model'
import { UpdateUser } from 'domain/usecases/user/update-user'

type Props = {
  loadDoctor: LoadDoctor
  updateSecreatry: UpdateUser
}

export default function Secretaries({ loadDoctor }: Props) {
  const [secretaries, setSecretaries] = useState<Secretary[]>()

  useEffect(() => {
    async function loadData() {
      const doctor = await loadDoctor.load([
        'secretary {name, lastName, cpf, phone, email, secretary_id, user_id}'
      ])
      setSecretaries(doctor.secretary)
    }
    loadData()
  }, [])

  return <SecretariesLayout secretaries={secretaries} />
}
