import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteAddSecretary } from 'service/usecases/add-secretary/remote-add-secretary'
import AddSecretary from 'presentation/doctor/pages/AddProfessional/AddSecretary'

export default function makeDoctorAddSecretary() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const secretaryRepository = new SecretaryRepository(apiRepository)

  const addSecretary = new RemoteAddSecretary(secretaryRepository)
  return <AddSecretary addSecretary={addSecretary} />
}
