import { IApiRepository } from 'service/protocols/api/api-repository'
import { AllergyRepository as IAllergyRepository } from 'repository/interfaces/allergy-repository'
import { Allergy } from 'domain/entities/allergy-model'
import { AddAllergy } from 'domain/usecases/allergy/add-allergy'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { addAllergyMutation } from 'repository/graphql/mutations'

export class AllergyRepository implements IAllergyRepository {
  constructor(private readonly apiRepository: IApiRepository) {}
  async addAllergy(params: AddAllergy.Params): Promise<Allergy> {
    const apiRepository = this.apiRepository as IApiRepository<AddAllergy.Model>

    const query = addAllergyMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddAllergy.Model
    }
  }
}
