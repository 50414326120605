import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadSurgicalOrderByDoctor
  implements LoadSurgicalOrdersByDoctor
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  loadOrders(
    params: LoadSurgicalOrdersByDoctor.Params
  ): Promise<LoadSurgicalOrdersByDoctor.Model> {
    return this.doctorRepository.loadSurgicalOrdersByDoctor(params)
  }
}
