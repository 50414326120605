import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  button {
    margin-top: auto;
  }
`

export const BoxLabel = styled.h2``

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;

  p {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    cursor: pointer;
  }
`
