import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  padding-bottom: 10px;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`

export const Controls = styled.div`
  top: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${({ theme }) => theme.layers.overlay};
`
export const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  p {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  strong {
    color: ${({ theme }) => theme.colors.primary};
  }
`
