import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    color: white;
    left: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;
    flex: 1 0;
    #sig-canvas {
      background-color: white;
    }
    @media (min-width: 700px), (orientation: landscape) {
      display: flex;
    }
  `}
`

export const Footer = styled.footer<{ lineWidth: number }>`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    max-width: 700px;
    width: 100%;
    position: relative;
    padding: 10px 46px;

    &:before {
      content: '';
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 90%;
      position: absolute;
      background-color: #9b9b9b;
    }

    svg {
      width: 23px;
      height: 23px;
      background-color: ${theme.colors.secondary};
      border-radius: 3px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(-90deg);
      padding: 2px;
    }

    span {
      color: ${theme.colors.lightGray};
    }

    div {
      display: flex;
      cursor: pointer;

      span {
        margin-left: 12px;
        color: ${theme.colors.secondary};
      }
    }
  `}
`
