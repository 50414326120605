import { AddAllergy } from 'domain/usecases/allergy/add-allergy'
import { AllergyRepository } from 'repository/interfaces/allergy-repository'

export class RemoteAddAllergy implements AddAllergy {
  constructor(private readonly allergyRepository: AllergyRepository) {}

  add(params: AddAllergy.Params): Promise<AddAllergy.Model> {
    return this.allergyRepository.addAllergy(params)
  }
}
