import React from 'react'

import { useHistory } from 'react-router-dom'

import * as S from './styles'
import { Doctor } from 'domain/entities/doctor-model'
import ActualPage from 'presentation/shared/components/ActualPage'
import Heading from 'presentation/shared/components/Heading'
import { Header } from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { ReactComponent as SignatureIcon } from 'presentation/assets/icons/signature-icon.svg'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import Button from 'presentation/shared/components/Button'

type Props = {
  doctor: Doctor
}

export default function SignatureLayout({ doctor }: Props) {
  const history = useHistory()

  return (
    <>
      <Header />
      <Container hasHeader>
        <S.Wrapper>
          <ActualPage text="Assinatura" onClick={history.goBack} />
          <Heading as="h1">Cadastro de assinatura</Heading>
          <S.SignatureWrapper>
            <div>
              <SignatureIcon />
            </div>
            <div>
              <h5>{doctor.name}</h5>
            </div>
          </S.SignatureWrapper>
        </S.Wrapper>
        <Button fullWidth onClick={() => history.push('/assinatura/save')}>
          Alterar assinatura
        </Button>
      </Container>
      <BottomMenu />
    </>
  )
}
