import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import DoctorSignup from 'presentation/doctor/pages/Signup'
import { RemoteLoadDoctorByInviteToken } from 'service/usecases/load-doctor-by-invite-token/remote-load-doctor-by-invite-token'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteFinishDoctorSignup } from 'service/usecases/finish-doctor-signup/remote-finish-doctor-signup'

export default function makeDoctorSignup() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const doctorRepository = new DoctorRepository(apiRepository)

  const finishDoctorSignup = new RemoteFinishDoctorSignup(doctorRepository)
  const loadDoctorByInviteToken = new RemoteLoadDoctorByInviteToken(
    doctorRepository
  )
  return (
    <DoctorSignup
      finishSignup={finishDoctorSignup}
      loadDoctorByInviteToken={loadDoctorByInviteToken}
    />
  )
}
