import { AddSecretary } from 'domain/usecases/secretary/add-secretary'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteAddSecretary implements AddSecretary {
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  add(params: AddSecretary.Params): Promise<AddSecretary.Model> {
    return this.secretaryRepository.addSecretary(params)
  }
}

export type AddSecretaryModel = AddSecretary.Model
