import { ScheduleStatusEnum } from 'common/enum/schedule-status'

export const ScheduleStatusBind: Record<string, ScheduleStatusEnum> = {
  G: ScheduleStatusEnum.SCHEDULED,
  P: ScheduleStatusEnum.PRESENT,
  M: ScheduleStatusEnum.MARKED,
  A: ScheduleStatusEnum.WAITING,
  E: ScheduleStatusEnum.ATTENDED,
  C: ScheduleStatusEnum.CANCELLED
}
