import React, { useEffect, useState } from 'react'
import DoctorSignupLayout from 'presentation/doctor/layouts/Signup'
import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { useParams } from 'react-router'
import { Doctor } from 'domain/entities/doctor-model'
import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'

type DoctorSignupProps = {
  loadDoctorByInviteToken: LoadDoctorByInviteToken
  finishSignup: FinishDoctorSignup
}

type RouteParams = {
  hash: string
}

export default function DoctorSignup({
  finishSignup,
  loadDoctorByInviteToken
}: DoctorSignupProps) {
  const { hash } = useParams<RouteParams>()
  const [doctorData, setDoctorData] = useState({} as Doctor)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      const doctorRequestData = await loadDoctorByInviteToken.load(hash, [
        'cpf',
        'crm',
        'crmUf',
        'doctor_id',
        'user_id'
      ])

      setDoctorData(doctorRequestData)
      setIsLoading(false)
    }
    getData()
  }, [])

  if (isLoading) {
    return <></>
  } else {
    return (
      <DoctorSignupLayout
        initialValues={
          {
            cpf: doctorData.cpf,
            crm: doctorData.crm,
            uf: doctorData.crmUf
          } as SignupDoctorValues
        }
        inviteToken={hash}
        finishSignup={finishSignup}
      />
    )
  }
}

export type SignupDoctorValues = {
  cpf: string
  crm: string
  uf: string
  password: string
  passwordConfirmation: string
}
