import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { UserRepository } from 'repository/repositories/user/user-repository'
import { RemoteFinishAppOnboarding } from 'service/usecases/finish-app-onboarding/remote-finish-app-onboarding'
import DoctorOnboarding from 'presentation/doctor/pages/DoctorOnboarding'

export default function makeOnboarding() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const userRepository = new UserRepository(apiRepository)
  const finishAppOnboarding = new RemoteFinishAppOnboarding(userRepository)

  return <DoctorOnboarding finishAppOnboarding={finishAppOnboarding} />
}
