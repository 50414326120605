import React from 'react'

import AddProfessionalForm from 'presentation/doctor/components/Forms/AddProfessional'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'

export default function AddProfessionalLayout() {
  return (
    <>
      <Header />
      <Container hasHeader>
        <AddProfessionalForm />
      </Container>
    </>
  )
}
