import React from 'react'

import { Container } from 'presentation/shared/components/Container'
import { AddAllergy } from 'domain/usecases/allergy/add-allergy'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import Header from 'presentation/shared/components/Header'
import AddAllergyForm from 'presentation/doctor/components/Forms/AddAllergy'

type Props = {
  addAllergy?: AddAllergy
  patient_id: number
}

export default function AddAllergyLayout({ addAllergy, patient_id }: Props) {
  return (
    <>
      <Header />
      <Container>
        <AddAllergyForm addAllergy={addAllergy} patient_id={patient_id} />
      </Container>
      <BottomMenu />
    </>
  )
}
