import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteLoadDoctor } from 'service/usecases/load-doctor/remote-load-doctor'
import Secretaries from 'presentation/doctor/pages/Secretaries'
import { RemoteUpdateUser } from 'service/usecases/user-update/user-update'
import { UserRepository } from 'repository/repositories/user/user-repository'

export default function makeDoctorSecretaries() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const doctorRepository = new DoctorRepository(apiRepository)
  const userRepository = new UserRepository(apiRepository)

  const loadDoctor = new RemoteLoadDoctor(doctorRepository)
  const updateScretaryProfile = new RemoteUpdateUser(userRepository)

  return (
    <Secretaries
      loadDoctor={loadDoctor}
      updateSecreatry={updateScretaryProfile}
    />
  )
}
