import { Allergies } from 'common/enum/allergies'

export const defaultAllergies = [
  { label: 'Medicamentosa', value: Allergies.MEDICINES },
  {
    label: 'Alimentar',
    value: Allergies.FOOD
  },
  { label: 'Produto', value: Allergies.PRODUCT }
]
