import React from 'react'
import SignupDoctorForm from 'presentation/doctor/components/Forms/SignupDoctorForm'
import { SignupDoctorValues } from 'presentation/doctor/pages/Signup'
import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'

type DoctorSignupLayoutProps = {
  finishSignup?: FinishDoctorSignup
  initialValues?: SignupDoctorValues
  inviteToken?: string
}

export default function DoctorSignupLayout({
  finishSignup,
  initialValues,
  inviteToken
}: DoctorSignupLayoutProps) {
  return (
    <SignupDoctorForm
      initialValues={initialValues}
      finishSignup={finishSignup}
      inviteToken={inviteToken}
    ></SignupDoctorForm>
  )
}
