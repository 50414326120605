import SupportText from 'presentation/shared/components/SupportText'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
`

export const Subtitle = styled(SupportText)`
  @media (min-width: 700px), (orientation: landscape) {
    display: none;
  }
`
