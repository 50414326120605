import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteFinishDoctorSignup implements FinishDoctorSignup {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  add(params: FinishDoctorSignup.Params): Promise<FinishDoctorSignup.Model> {
    return this.doctorRepository.finishDoctorSignup(params)
  }
}

export type FinishDoctorSignupModel = FinishDoctorSignup.Model
