import { UnlinkSecretary } from 'domain/usecases/secretary/unlink-secretary'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteUnlinkSecretary implements UnlinkSecretary {
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  delete(params: UnlinkSecretary.Params): Promise<UnlinkSecretary.Model> {
    return this.secretaryRepository.unlinkSecretary(params)
  }
}
