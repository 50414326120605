import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  h1 {
    margin-top: 35px;
  }
`

export const SignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 288px;
  margin-top: 40px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);

  div + div {
    margin-left: 13px;
  }
`
