import React from 'react'
import AddSignatureLayout from 'presentation/doctor/layouts/AddSignature'
import { AddDoctorSignature } from 'domain/usecases/doctor/add-signature'

type Props = {
  addDoctorSignature: AddDoctorSignature
}

export const AddSignature = ({ addDoctorSignature }: Props) => {
  return <AddSignatureLayout addDoctorSignature={addDoctorSignature} />
}

export default AddSignature
