import React from 'react'

import AddSecretaryLayout from 'presentation/doctor/layouts/AddProfessional/AddSecretary'
import { AddSecretary as AddSecretaryUseCase } from 'domain/usecases/secretary/add-secretary'

type AddSecretaryProps = {
  addSecretary: AddSecretaryUseCase
}

export default function AddSecretary({ addSecretary }: AddSecretaryProps) {
  return <AddSecretaryLayout addSecretary={addSecretary} />
}
