import { AddDoctorSignature } from 'domain/usecases/doctor/add-signature'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteAddDoctorSignature implements AddDoctorSignature {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  upload(params: AddDoctorSignature.Params): Promise<AddDoctorSignature.Model> {
    return this.doctorRepository.addFavorite(params)
  }
}
